import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    public translate: TranslateService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
  }

  sort(collection: Array<any>): Array<any> {
    collection.sort((a: any, b: any) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return collection;
  }

  sortByNumber(collection: Array<any>, property: string): Array<any> {
    collection.sort((a: any, b: any) => {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    });
    return collection;
  }

  sortBy(collection: Array<any>, property: string): Array<any> {
    collection.sort((a: any, b: any) => {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    });
    return collection;
  }

  /**
   * To sort an array by specified field.
   * Usage: [].sort(sortArrayObjectsByField('sort_by_field_name))
   * @param property Sort field
   * @author Pratik Padia
   */
   sortArrayObjectsByField(property: string) {
    let sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substring(1);
    }
    return (a: any, b: any) => {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    };
  }

  shuffle(array: any[]): any[] {
    let currentIndex = array.length;
    let randomIndex: number;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  }



  // Rounding the minute to 0, 5, 10, 15, 20
  public createRoundDate(d: Date, hours?: number): Date | string {
    const date: moment.Moment = moment(d);
    if (hours) {
      date.set({hour: hours, minute: 0});
    }
    const minutes: number = date.minutes();
    const mod: number = minutes % 10;
    let finalMinutes: number = minutes;
    if (mod < 5) {
      finalMinutes = minutes - mod;
    } else if (mod > 5) {
      finalMinutes = minutes - (mod - 5);
    }
    date.set('minutes', finalMinutes);
    return date.toDate();
  }

  dateAgoUtility(value: string): string {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        return 'Just now';
      } // less than 30 seconds ago will show as 'Just now'
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
      };
      let counter;
      for (const i in intervals) {
        if (intervals[i]) {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0) {
            if (counter === 1) {
              let translation: string = this.translate.instant(`notification_component.x_${i + '_ago'}`);
              // LoggerService.log('translation if is ', translation);
              translation = translation.replace('x ', counter + ' ');
              // LoggerService.log('final translation is ', translation)
              return translation; // singular (1 day ago)
            } else {
              let translation: string = this.translate.instant(`notification_component.x_${i + 's_ago'}`);
              // LoggerService.log('translation if is ', translation);
              translation = translation.replace('x ', counter + ' ');
              // LoggerService.log('final translation is ', translation)
              return translation; // plural (2 days ago)
            }
          }
        }
      }
    }
    return value;
  }

  public createListOfYearsOnAbsenseForm(currentYear: number): number[] {
    const listOfYears: number[] = [];
    // listOfYears.push(currentYear + 1);
    for (const i of [0, 1]) {
      listOfYears.push(currentYear + i);
    }
    return listOfYears;
  }

  public createListOfYears(currentYear: number): number[] {
    const listOfYears: number[] = [];
    listOfYears.push(currentYear + 1);
    for (const i of [0, 1]) {
      listOfYears.push(currentYear - i);
    }
    return listOfYears;
  }

  isEmptyString(value: string): string {
    if (value) {
      if (value.trim() === '') {
        return null;
      } else {
        return value;
      }
    } else {
      return null;
    }
  }

  public convertBooleanToNumber(value: number | boolean): number {
    if (value) {
      value = 1;
    } else {
      value = 0;
    }
    return value;
  }

  public replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  public formatDate(date: string): string {
    if (!date) {
      return null;
    }
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  isIEorEdgeBrowser(): boolean {
    if (window) {
      const isIE = /edge|msie\s|trident\//i.test(window.navigator.userAgent);
      return isIE;
    }
    return false;
  }

  getRandomColor(): string {
    const letters: string = '0123456789ABCDEF';
    let color: string = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getBrowserName(): string {
    if ((navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR')) !== -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }

  mergeTimeRanges(collection: Array<any>, start: string, end: string): any[] {
    const tempList: any[] = [];
    let current: any;
    let previous: any;
    let index: number = Number(0);
    for (const item of collection) {
      if (!previous) {
        current = Object.assign({}, item);
        previous = Object.assign({}, item);
        if (collection.length === 1) {
          tempList.push(Object.assign({}, current));
        }
      } else {
        if (previous.end === item.start) {
          current.end = item.end;
          previous = Object.assign({}, item);
          if (typeof collection[index + 1] === 'undefined') {
            tempList.push(Object.assign({}, current));
          }
        } else {
          tempList.push(Object.assign({}, current));
          current = Object.assign({}, item);
          previous = Object.assign({}, item);
          if (typeof collection[index + 1] === 'undefined') {
            tempList.push(Object.assign({}, item));
          }
        }
      }
      index++;
    }
    return tempList;
  }

  getTeaserMessage(placeHolder: string): string {
    switch (placeHolder) {
      case '[TODAY]':
        return 'teaser_component.today_message';
      case '[TOMORROW]':
        return 'teaser_component.tomorrow_message';
      default:
        return 'teaser_component.date_message';
    }
  }

  groupBy(
    collection: Array<any>,
    groupByKey: string,
    sortByKey: string,
    sortNestedArrayKey?: string,
    additionalKey1?: string,
    additionalKey2?: string
  ): Array<any> {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }

    if (groupByKey) {
      collection = this.sortBy(collection, sortByKey);
    } else {
      return null;
    }

    const groupedCollection = collection.reduce((previous, current) => {
      if (!previous[current[groupByKey]]) {
        previous[current[groupByKey]] = [current];
      } else {
        previous[current[groupByKey]].push(current);
      }

      return previous;
    }, {});
    LoggerService.log('groupedCollection ', groupedCollection);
    // LoggerService.log('Object.keys(groupedCollection) ', Object.keys(groupedCollection).sort());
    // this will return an array of objects, each object containing a group of objects
    const finalDataList: { key: any, value: any[], temp: any }[] = Object.keys(groupedCollection).sort().map(key => (
      {
        key,
        value: sortNestedArrayKey ? this.sortBy(groupedCollection[key], sortNestedArrayKey) : groupedCollection[key],
        temp: {}
      }));

    if (finalDataList && finalDataList.length > 0) {
      if (additionalKey1 || additionalKey2) {
        for (const finalData of finalDataList) {
          if (finalData.value && finalData.value.length > 0) {
            if (additionalKey1) {
              finalData.temp[additionalKey1] = finalData.value[0][additionalKey1];
            }
            if (additionalKey2) {
              finalData.temp[additionalKey2] = finalData.value[0][additionalKey2];
            }
          }
        }
      }
    }

    LoggerService.log('Inside CalioGroupByPipe finalDataList ', finalDataList);

    return finalDataList;
  }

  public loadStripeJs(): void {
    try {
      const script = document.createElement('script');
      script.src = `https://js.stripe.com/v3/`;
      document.head.appendChild(script);
    } catch (ex) {
      LoggerService.error(ex);
    }
  }

  public findStringBetween(str: string, char1: string, char2: string): string {
    return str.split(char1).pop().split(char2)[0];
  }

  public createArrayRange(range: number): number[] {
    return new Array(range);
  }

  /**
   * Identify specific storage is available or not
   * @param type Type of storage to check if it is available or not
   * @returns boolean
   */
  storageAvailable(type: string): boolean {
    try {
      const storage = window?.[type];
      const x = '__calenso_storage_test__';

      storage.setItem(x, x);
      storage.removeItem(x);

      return true;
    } catch (e) {
      return false;
    }
  }

  registerSvgIcon(iconName: string, assetPath: string): void {
    this.iconRegistry.addSvgIcon(
      iconName,
      this.sanitizer.bypassSecurityTrustResourceUrl(assetPath)
    );
  }
}

<!-- <ngx-intl-tel-input
  [inputId]="id"
  [cssClass]="'calio-tel-input ' +  customClass"
  [preferredCountries]="preferredCountries"
  [enableAutoCountrySelect]="true"
  [enablePlaceholder]="true"
  [searchCountryFlag]="true"
  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
  [selectFirstCountry]="false"
  [phoneValidation]="true"
  [separateDialCode]="separateDialCode"
  [formControl]="telInputControl"
  [maxLength]="15 + ''"
  [selectedCountryISO]="CountryISO.Switzerland"
  [required]="isRequired"
  [numberFormat]="PhoneNumberFormat.International"
  #telInput
>
</ngx-intl-tel-input> -->

<input
  type="text"
  class="calio-tel-input"
  [ngClass]="cssClass"
  [attr.id]="name"
  [attr.name]="name"
  [name]="name"
  [formControl]="telInputControl"
  (countrychange)="i18nizePhoneNumber()"
  (click)="onClick()"
  (blur)="onBlur()"
  #intlTelInput
/>
